var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("label", [_vm._v(_vm._s(_vm.label))]),
    _c(
      "div",
      {
        staticClass:
          "flex flex-col justify-center text-sm text-gray-700 appearance-none gap-2",
        class: { "bg-gray-100": _vm.readonly },
      },
      _vm._l(_vm.radioItems, function (radioItem, index) {
        return _c("label", { key: index }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.val,
                expression: "val",
              },
            ],
            attrs: { disabled: _vm.readonly, type: "radio" },
            domProps: {
              value: radioItem.value,
              checked: _vm._q(_vm.val, radioItem.value),
            },
            on: {
              change: function ($event) {
                _vm.val = radioItem.value
              },
            },
          }),
          _c("span", { staticClass: "mx-2" }, [
            _vm._v(_vm._s(radioItem.label)),
          ]),
        ])
      }),
      0
    ),
    _vm.showError
      ? _c("div", { staticClass: "mt-2 text-xs text-red-500" }, [
          _vm._v(" " + _vm._s(_vm.errorText) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }