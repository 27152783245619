
import InlineRadioComponent from '@/components/Forms/Elements/InlineRadioComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import { EUserRoles } from '@/enums';
import SnackbarMixin from '@/mixins/snackbarMixin';
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { User } from '@/models/Entities/User';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Routes from '@/router/Routes';
import { deepCopy } from '@/util/deepCopy';

@Component<NewUserPage>({
  components: {
    InlineRadioComponent,
    ButtonComponent,
    IconComponent
  }
})
export default class NewUserPage extends Mixins(Vue, SnackbarMixin) {
  roleOptions = [
    { label: 'Recruiter', value: EUserRoles.RECRUITER },
    { label: 'Unit Hiring PoC', value: EUserRoles.UNIT_REPRESENTATIVE },
    { label: 'Flight Chief', value: EUserRoles.FLIGHT_CHIEF },
    {
      label: '340th FTG Jr. Member',
      value: EUserRoles.THREE_40TH_FTG_JUNIOR_MEMBER
    },
    {
      label: '340th FTG Sr. Member',
      value: EUserRoles.THREE_40TH_FTG_SENIOR_MEMBER
    },
    {
      label: 'Other',
      value: EUserRoles.MEMBER
    }
  ];

  selectedRole: EUserRoles | null = null;

  get currentUser(): User {
    return this.$store.getters['userModule/currentUser'];
  }

  async updateRole(): Promise<void> {
    try {
      const user = deepCopy(this.currentUser);
      user.role = this.selectedRole;
      await this.$store.dispatch('userModule/setUserInitialRole', {
        role: this.selectedRole
      });
      this.$router.push({ name: Routes.HOME });
    } catch {
      this.showSnackbar({
        message: 'There was an error with setting your role, please try again.',
        icon: 'caution'
      });
    }
  }
}
