
import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import { RadioOptions, FormOptionValue } from '@/models/Forms/FormOption';

@Component<InlineRadioComponent>({})
export default class InlineRadioComponent extends Vue {
  @VModel()
  val!: FormOptionValue;

  @Prop({ default: '' })
  label!: string;

  @Prop({
    default: () => []
  })
  radioItems!: RadioOptions[];

  @Prop({ default: 'Please select an option' })
  errorText!: string;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: false })
  readonly!: boolean;

  @Prop({ default: false })
  showError!: boolean;
}
