/**
 * returns a deep copy of an object
 *
 * @export
 * @template T
 * @param {T} object
 * @returns  {T}
 */
export function deepCopy<T>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}
