var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "center-contents-page" }, [
    _c(
      "div",
      { staticClass: "flex flex-col w-1/2 p-16 bg-white" },
      [
        _c("img", {
          staticClass: "w-auto h-32 mx-auto mb-6",
          attrs: {
            src: require("@/assets/images/skypatchLogo.png"),
            alt: "Skypatch Logo",
          },
        }),
        _vm._m(0),
        _c("div", { staticClass: "-mx-6 border border-gray-200 border-b-1" }),
        _c("p", { staticClass: "my-4" }, [
          _vm._v("To continue, select your user role below."),
        ]),
        _vm._m(1),
        _c("inline-radio-component", {
          staticClass: "pt-4",
          attrs: { radioItems: _vm.roleOptions },
          model: {
            value: _vm.selectedRole,
            callback: function ($$v) {
              _vm.selectedRole = $$v
            },
            expression: "selectedRole",
          },
        }),
        _c(
          "button-component",
          {
            staticClass: "mt-4 w-28",
            class: { "opacity-50": !_vm.selectedRole },
            attrs: {
              disabled: !_vm.selectedRole,
              textOrBorderColor: "white",
              bg: "val-button-blue",
            },
            on: { click: _vm.updateRole },
          },
          [_c("div", [_vm._v("Continue")])]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex justify-center" }, [
      _c("h1", { staticClass: "mb-6 text-5xl text-primary-dark" }, [
        _vm._v("Welcome to Skypatch!"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "inline-block" }, [
      _c("span", { staticClass: "inline font-bold" }, [
        _vm._v(" This can only be changed by an administrator. "),
      ]),
      _c("span", [
        _vm._v(
          ' If your role is not listed below, or you are unsure, select "Other" and proceed by contacting your supervisor, or another administrator to activate your account with the appropriate user role. '
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }